@font-face {
    font-family: 'Golos';
    src: url(../public/assets/fonts/golos-text_regular.ttf);
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: url(../public/assets/fonts/golos-text_regular.ttf);
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: url(../public/assets/fonts/golos-text_medium.ttf);
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: url(../public/assets/fonts/golos-text_demibold.ttf);
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: url(../public/assets/fonts/golos-text_bold.ttf);
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: url(../public/assets/fonts/golos-text_bold.ttf);
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html {
    background-color: #e8e8ea;
}

body {
    margin: 0;
    font-family: 'Golos';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* COLORS */
    --color-primary: #FF443B;
    --color-dark-purple: #2e124c;
    --color-white: #fff;
    --bg-primary: #FF443B;
    --bg-gray: #e8e8ea;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    display: flex;
    justify-content: center;
}

.drawer-menu .ant-drawer-body {
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: center;
}

.drawer-menu .ant-drawer-body img.logo {
    max-width: 120px;
    margin: 0 auto;
}

.drawer-menu .ant-drawer-body .login-btn {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.drawer-menu .ant-drawer-body .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.drawer-menu .ant-drawer-header-title {
    justify-content: flex-end;
}

.drawer-menu .ant-drawer-header-close-only {
    padding-right: 10px;
}

.drawer-menu button.active {
    color: var(--color-primary);
}